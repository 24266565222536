<!-- src/App.vue -->
<template>
  <div id="app">
    <div class="cards">
      <div class="card">
        <h3>Temperatura Media (Últimas 24 horas)</h3>
        <p>{{ averageTemperature }} °C</p>
        <h3>Fecha y hora de la temperatura media</h3>
        <p>{{ chartData.labels[chartData.labels.length - 1] }}</p>
        <h3>Temperatura</h3>
        <p>{{ temperature }} °C</p>
        <h3>Fecha y hora de la temperatura</h3>
        <p>{{ chartData.labels[chartData.labels.length - 1] }}</p>
      </div>
      <div class="card">
        <h3>Humedad Media (Últimas 24 horas)</h3>
        <p>{{ averageHumidity }} %</p>
        <h3>Fecha y hora de la humedad media</h3>
        <p>{{ chartData.labels[chartData.labels.length - 1] }}</p>
        <h3>Humedad</h3>
        <p>{{ humidity }} %</p>
        <h3>Fecha y hora de la humedad</h3>
        <p>{{ chartData.labels[chartData.labels.length - 1] }}</p>
      </div>
    </div>
    <line-chart :chart-data="chartData"></line-chart>
  </div>
</template>

<script>
  import LineChart from './components/LineChart.vue'

  export default {
    components: {
      LineChart
    },
    data() {
      return {
        data: [],
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Temperatura (°C)',
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              borderColor: 'rgba(255, 99, 132, 1)',
              data: []
            },
            {
              label: 'Humedad (%)',
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
              data: []
            }
          ]
        },
        averageTemperature: 0,
        averageHumidity: 0,
        humidity: 0,
        temperature: 0
      }
    },
    async mounted() {
      await this.fetchData()
      setInterval(() => {
        this.fetchData()
      }, 5000)
    },
    methods: {
      async fetchData() {
        try {
          const response = await fetch('/data',).then(response => response.json())
          this.data = response
          this.humidity = response[0].humidity
          this.temperature = response[0].temperature
          this.updateChartData()
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      },
      updateChartData() {
        const timestamps = []
        const temperatures = []
        const humidities = []

        this.data.forEach(entry => {
          timestamps.push(new Date(entry.timestamp).toLocaleString())
          temperatures.push(entry.temperature)
          humidities.push(entry.humidity)
        })

        this.chartData.labels = timestamps
        this.chartData.datasets[0].data = temperatures
        this.chartData.datasets[1].data = humidities

        this.averageTemperature = (
          temperatures.reduce((a, b) => a + b, 0) / temperatures.length
        ).toFixed(2)

        this.averageHumidity = (
          humidities.reduce((a, b) => a + b, 0) / humidities.length
        ).toFixed(2)
      }
    }
  }
</script>

<style>
  .cards {
    display: flex;
    justify-content: space-around;
    padding: 20px;
  }

  .card {
    padding: 10px;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
  }

  .line-chart {
    padding: 20px;
  }
</style>