<!-- src/components/LineChart.vue -->
<template>
    <div class="line-chart">
      <canvas ref="canvas" :id="id"></canvas>
    </div>
  </template>
  
  <script>
  import Chart from 'chart.js/auto'
  
  export default {
    props: {
      chartData: Object,
      id: String
    },
    data() {
      return {
        chart: null
      }
    },
    watch: {
      chartData: {
        handler() {
          this.renderChart()
        },
        deep: true
      }
    },
    mounted() {
      this.renderChart()
    },
    methods: {
      renderChart() {
        if (this.chart) {
          this.chart.destroy()
        }
        this.chart = new Chart(this.$refs.canvas.getContext('2d'), {
          type: 'line',
          data: this.chartData,
          options: {
            responsive: true,
            maintainAspectRatio: false
          }
        })
      }
    }
  }
  </script>
  
  <style>
  .line-chart {
    position: relative;
    height: 300px;
  }
  </style>
  